<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 215px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" style="width: 130px">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th style="min-width: 120px">
              <SelectFilter
                :options="customerOptions(0)"
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="id_customer"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 130px">
              <SelectFilter
                :options="typeOptions"
                :label="$t('labels.type')"
                :placeholder="$t('labels.type')"
                name="type"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader" style="width: 150px">
              <SelectFilter
                :options="contractOptions(0)"
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_contract"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.max_weight')"
                :placeholder="$t('labels.max_weight')"
                name="max_weight"
                sort-name="max_weight"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.set_weight')"
                :placeholder="$t('labels.set_weight')"
                name="weight"
                sort-name="weight"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 90px">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :default-value="filters.active"
                name="active"
                sort-name="active"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="min-width: 98px">
              <v-btn
                small
                color="primary"
                @click="addItem"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, key) in items"
            :key="`dc_${item.id}`"
          >
            <td>
              <select-warehouse-model
                :disabled="!item.editing || !!item.id"
                v-model="item.id_warehouse"
                single-line
                label=""
              ></select-warehouse-model>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing || !item.id_warehouse || !!item.id"
                class="c-input-xs"
                v-model="item.id_customer"
                :items="customerOptions(item.id_warehouse)"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.type"
                :items="typeOptions"
                :disabled="!item.editing || !!item.id"
                :label="$t('labels.type')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-small"
                v-model="item.id_delivery_contract"
                :items="contractOptions(item.id_warehouse)"
                :label="$t('labels.delivery_company')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <input-number
                :disabled="!item.editing"
                v-model="item.max_weight"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </td>
            <td>
              <input-number
                :disabled="!item.editing"
                v-model="item.weight"
                outlined
                dense
                hide-details
                class="c-input-small"
              />
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, key, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveConfig(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, key, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div>
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import {
  YES_NO_OPTIONS,
  DELIVERY_TYPE_INTER_PROVINCIAL,
  DELIVERY_TYPE_PROVINCIAL,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "WeightConfig",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  data: () => ({
    filters: {},
    isLoading: false,
    page: 1,
    totalPage: 1,
    totalItem: 0,
    items: [],
    customers: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    deliveryContracts: [],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    typeOptions() {
      return [
        {
          value: DELIVERY_TYPE_PROVINCIAL,
          text: "Nội tỉnh",
        },
        {
          value: DELIVERY_TYPE_INTER_PROVINCIAL,
          text: "Liên tỉnh",
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
    this.getCustomers();
    this.getContracts();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    addItem() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_warehouse: null,
        id_pos: null,
        id_delivery_contract: null,
        max_weight: "",
        weight: "",
        type: null,
        editing: true,
        status: 1,
      });
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    customerOptions(id_warehouse) {
      let customers = [...this.customers];
      if (id_warehouse) {
        customers = [...customers].filter(
          (cus) => cus.id_warehouse == id_warehouse
        );
      }
      return [...customers].map((cus) => ({
        text: cus.company_name,
        value: cus.id,
      }));
    },
    async getCustomers() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract-warehouse",
        { is_active: true }
      );
      this.customers = [...data];
    },
    contractOptions(id_warehouse) {
      let contracts = [...this.deliveryContracts];
      if (id_warehouse) {
        contracts = [...contracts].filter(
          (ct) => ct.id_warehouse == id_warehouse
        );
      }
      return [...contracts].map((ct) => ({
        text: ct.delivery_company_short_name,
        value: ct.id,
      }));
    },
    getContracts() {
      httpClient
        .post("/delivery/v1/get-delivery-contract-by-customer", {
          status: 1,
          has_owner: true,
        })
        .then(({ data }) => {
          this.deliveryContracts = [...data].map((contract) => {
            const account_info = JSON.parse(contract.account_info);
            const keys = Object.keys(account_info);
            const txts = [];
            txts.push(contract.delivery_company_short_name);
            txts.push(". ");
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              if (account_info[key]) {
                txts.push(key);
                txts.push(": ");
                txts.push(this.truncateString(account_info[key], 8));
                txts.push(".");
              }
            }
            return {
              ...contract,
              delivery_company_short_name: txts.join(""),
            };
          });
        });
    },
    async saveConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/delivery/v1/save-weight-config", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getList() {
      try {
        const { data } = await httpClient.post(
          "/delivery/v1/list-weight-config",
          {
            ...this.filters,
          }
        );
        this.totalPage = data.totalPage;
        this.totalItem = data.total;
        this.items = [...data.rows].map((row) => ({
          ...row,
          editing: false,
        }));
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
