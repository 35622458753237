import { render, staticRenderFns } from "./Weight.vue?vue&type=template&id=6a0ecc0f&scoped=true&"
import script from "./Weight.vue?vue&type=script&lang=js&"
export * from "./Weight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0ecc0f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VPagination,VSimpleTable})
